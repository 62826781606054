import type { FC } from 'react'
import React from 'react'
import { Skeleton } from '@goatlab/react-ui'

export const LandingPageLoadingShimmer: FC = () => {
  return (
    <div className="flex h-full w-full flex-col">
      {/* Header */}
      <div className="flex items-center border-b p-4">
        <Skeleton className="h-10 w-40 rounded-md" /> {/* Logo */}
        <div className="flex-grow"></div>
        {Array.from({ length: 5 }).map((_, idx) => (
          <Skeleton key={idx} className="mx-2 h-6 w-24 rounded-md" />
        ))}
      </div>

      {/* Carousel */}
      <div className="relative h-64 p-4">
        <Skeleton className="absolute left-0 top-0 h-full w-full rounded-md" />{' '}
        {/* Carousel image */}
        <div className="absolute bottom-0 left-0 p-4">
          <Skeleton className="h-12 w-48 rounded-md" /> {/* Call to action */}
        </div>
      </div>

      {/* Blog Posts */}
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3">
        {Array.from({ length: 6 }).map((_, idx) => (
          <div key={idx} className="flex flex-col rounded-md border p-4">
            <Skeleton className="mb-4 h-40 w-full rounded-md" />{' '}
            {/* Blog image */}
            <Skeleton className="mb-2 h-6 w-3/4 rounded-md" />{' '}
            {/* Blog title */}
            <Skeleton className="mb-4 h-4 w-1/2 rounded-md" />{' '}
            {/* Blog excerpt */}
            <Skeleton className="h-6 w-24 rounded-md" />{' '}
            {/* Read more button */}
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className="border-t p-4">
        {Array.from({ length: 3 }).map((_, idx) => (
          <div key={idx} className="flex space-x-4">
            {Array.from({ length: 4 }).map((_, idx2) => (
              <Skeleton key={idx2} className="h-8 w-32 rounded-md" />
            ))}
          </div>
        ))}
      </div>

      {/* Footer Links */}
      <div className="flex space-x-4 border-t p-4">
        {Array.from({ length: 5 }).map((_, idx) => (
          <Skeleton key={idx} className="h-6 w-24 rounded-md" />
        ))}
      </div>
    </div>
  )
}
