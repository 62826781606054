import { gcpService } from '@src/services/gcp/gcp.service'
import { backendRequest } from './trpc'

export interface ImageUploadProps {
  fileName: string
  imageBlob: Blob
  mime: string
}
class UploadService {
  public async uploadFile({
    fileName,
    imageBlob,
    mime,
  }: ImageUploadProps): Promise<{ url: string }> {
    const { signedUploadURL, url } =
      await this.getPrivateSignedUploadLink(fileName)

    await gcpService.uploadFileToSignedUrl({
      signedUrl: signedUploadURL,
      blob: imageBlob,
      mime,
    })

    return { url }
  }

  public async uploadPublicFile({
    fileName,
    imageBlob,
    mime,
  }: ImageUploadProps): Promise<{ url: string }> {
    const { signedUploadURL, url } =
      await uploadService.getPublicSignedUploadLink(fileName)

    await gcpService.uploadFileToSignedUrl({
      signedUrl: signedUploadURL,
      blob: imageBlob,
      mime,
    })

    return { url }
  }

  public async getPrivateSignedUploadLink(fileName: string) {
    return await backendRequest.backend.posts.getUploadSession.mutate({
      fileName,
      visibility: 'private',
    })
  }

  public async getPublicSignedUploadLink(fileName: string) {
    return await backendRequest.backend.posts.getUploadSession.mutate({
      fileName,
      visibility: 'public',
    })
  }
}

export const uploadService = new UploadService()
