import React from 'react'
import { Skeleton } from '@goatlab/react-ui'
import { cn } from '@src/utils/cn'
import { motion } from 'framer-motion'

export const PostGridSkeleton = () => {
  const itemSize = '32%' // Define el tamaño de los ítems en porcentaje para mantener la proporción

  const gridVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { type: 'tween', duration: 0.3 } },
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={gridVariants}
      className="space-y-2"
    >
      <div className="flex flex-wrap justify-between gap-1">
        <Skeleton className={cn('w-[', itemSize, '] h-[', itemSize, ']')} />
        <Skeleton className={cn('w-[', itemSize, '] h-[', itemSize, ']')} />
        <Skeleton className={cn('w-[', itemSize, '] h-[', itemSize, ']')} />
      </div>

      <div className="h-1"></div>

      <div className="flex flex-wrap justify-between gap-1">
        <Skeleton className={cn('w-[', itemSize, '] h-[', itemSize, ']')} />
        <Skeleton className={cn('w-[', itemSize, '] h-[', itemSize, ']')} />
        <Skeleton className={cn('w-[', itemSize, '] h-[', itemSize, ']')} />
      </div>
    </motion.div>
  )
}
