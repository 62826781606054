import type { ImageUploadProps } from '@api/uploads'
import type { FC } from 'react'
import { useState } from 'react'
import Image from 'next/image'
import { backendRequest } from '@api/trpc'
import { uploadService } from '@api/uploads'
import { useAuth } from '@components/auth/store/auth.store'
import { ImageUpload } from '@components/ui/images/ImageUpload'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  toastSonner,
} from '@goatlab/react-ui'
import { useMutation } from '@tanstack/react-query'
import { CameraIcon } from 'lucide-react'

// this is a message
interface ProfileBackgroundImageUploadProps {
  backgroundImageUrl: string
  isAccountOwner: boolean
  fallbackText?: string | null
}

export const ProfileBackgroundImageUpload: FC<
  ProfileBackgroundImageUploadProps
> = ({ backgroundImageUrl, isAccountOwner }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { refreshAccount: refreshProfile, user } = useAuth()

  const { mutateAsync, isPending } = useMutation({
    async mutationFn(image: ImageUploadProps) {
      toastSonner.promise(
        (async () => {
          if (!user?.account?.id) {
            return
          }
          const { url } = await uploadService.uploadFile(image)
          await backendRequest.backend.account.updateById.mutate({
            accountId: user?.account?.id,
            updatedAccount: {
              backgroundImage: url,
            },
          })
        })(),
        {
          loading: 'Uploading background image...',
          success: () => {
            refreshProfile()
            setIsDialogOpen(false)
            return 'Background image uploaded'
          },
          error: (error) => {
            return `There was an error uploading your background image ${error.message}`
          },
        },
      )
    },
  })

  return (
    <div>
      <div className="flex">
        <Image
          width={1400}
          height={1000}
          src={backgroundImageUrl}
          alt=""
          className="static z-10 h-[200px] w-full rounded-tl-md rounded-tr-md"
        />
        {isAccountOwner && (
          <div className="z-20 -ml-12 mt-5 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white">
            <CameraIcon
              onClick={() => setIsDialogOpen(!isDialogOpen)}
              className="h-5 w-5 text-gray-600"
            />
          </div>
        )}
      </div>
      <div>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="md:min-w-[650px]">
            <DialogHeader>
              <DialogTitle>Edit Background Image</DialogTitle>
            </DialogHeader>
            <div className="flex-1">
              <ImageUpload
                onUploadButtonPressed={async (data) => {
                  if (!isAccountOwner) return
                  await mutateAsync(data)
                }}
                isLoading={isPending}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
