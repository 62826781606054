import type { PostComment } from '@components/account/home/CommentsList'
import type { Post } from '@components/account/home/HomePosts'
import React, { useCallback, useState } from 'react'
import { api } from '@api/api'
import { CommentInput } from '@components/account/home/CommentInput'
import { CommentsList } from '@components/account/home/CommentsList'
import { FooterActionButton } from '@components/account/home/FooterActionButton'
import { PostAccount } from '@components/account/home/PostAccount'
import { AssetViewer } from '@components/account/post/assetViewer/AssetViewer'
import { RichTextDisplay } from '@components/ui/forms/richText/RichTextDisplay'
import { Functions } from '@goatlab/js-utils'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import {
  useAccountStore,
  useCommentActivitiesStore,
  useHomeFeedStore,
} from '@sodium/shared-frontend-schemas'
import { useIs } from '@src/hooks/useIs'
import { sodiumConfig } from '@src/sodiumConfig'
import { motion } from 'framer-motion'
import { MessageSquareMore, Repeat2 } from 'lucide-react'

interface AccountPostCarouselProps {
  post: Post
  showPostModal: () => void
  isViewable?: boolean
  isPreview?: boolean
  onLikesCountPressed?: (post: Post) => void
  onSharePressed?: (post: Post) => void
  onOptionsPressed?: (actity: Post) => void
}

export const AccountPostCarousel: React.FC<AccountPostCarouselProps> = ({
  post,
  showPostModal,
  isPreview,
  onLikesCountPressed,
  onSharePressed,
  isViewable,
}) => {
  const { isMobile } = useIs()
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTypedTranslation()
  const { setSelectedPost, visibleAccountId } = useHomeFeedStore()
  const { selectedAccount } = useAccountStore()
  const { reset } = useCommentActivitiesStore()

  const handleOpen = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const togglePostLike = api.posts.useTogglePostLike({
    account: {
      id: selectedAccount?.id || '',
      displayName: selectedAccount?.displayName || '',
      profilePicture: selectedAccount?.profilePicture || '',
      title: selectedAccount?.title || '',
    },
    visibleAccountId,
  })

  const toggleCommentLikeHook = api.posts.useToggleCommentLike({
    account: {
      id: selectedAccount?.id || '',
      displayName: selectedAccount?.displayName || '',
      profilePicture: selectedAccount?.profilePicture || '',
      title: selectedAccount?.title || '',
    },
    visibleAccountId,
  })

  const onCommentLikePressed = (comment: PostComment) => {
    if (!comment.postId || !selectedAccount?.id) {
      return
    }

    return toggleCommentLikeHook.mutateAsync({
      commentId: comment.id,
      selectedAccountId: selectedAccount?.id,
      postId: comment.postId,
    })
  }

  const _onCommentPressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }

      if (post.id) {
        reset()
        setSelectedPost(post)
      }

      requestAnimationFrame(() => {
        handleOpen()
      })
    }, 40),
    [post, open],
  )

  const _onSharePressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }

      requestAnimationFrame(() => {
        onSharePressed?.(post)
      })
    }, 40),
    [post],
  )

  const _onLikePressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }
      setSelectedPost(post)
      void togglePostLike.mutateAsync({ postId: post.id })
    }, 40),
    [post],
  )

  const _onLikesCountPressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }

      setSelectedPost(post)

      requestAnimationFrame(() => {
        onLikesCountPressed?.(post)
      })
    }, 40),
    [post],
  )

  const _onCommentsCountPressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }

      if (post.id) {
        reset()
        setSelectedPost(post)
      }

      requestAnimationFrame(() => {
        handleOpen()
      })
    }, 40),
    [post, open],
  )

  /* const _onOptionsPressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }

      if (post.id) {
        setSelectedPost(post)
      }

      requestAnimationFrame(() => {
        onOptionsPressed?.(post)
      })
    }, 40),
    [post],
  )*/

  const hasAssets = post?.assets.length > 0

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className="h-dvh w-full overflow-y-auto px-0 md:h-[560px] md:px-1"
    >
      <div className="h-full w-full max-w-full md:columns-2 md:gap-x-0">
        <div className="block md:hidden">
          <PostAccount
            post={post}
            disabled={isPreview}
            showPostModal={showPostModal}
          />
        </div>
        <div className="mx-auto flex h-auto w-full items-center justify-center md:h-full md:max-w-full">
          {hasAssets ? (
            <AssetViewer
              assets={post?.assets}
              isViewable={isViewable}
              postId={post.id}
            />
          ) : (
            <div className="flex h-auto w-full items-center justify-center bg-white px-4 md:h-[560px]">
              <RichTextDisplay content={post.content} />
            </div>
          )}
        </div>
        <div className="flex h-auto w-full flex-col justify-between md:h-full">
          <div className="hidden md:block">
            <PostAccount
              post={post}
              disabled={isPreview}
              showPostModal={showPostModal}
            />
          </div>
          <div className={`px-4 py-1 ${hasAssets ? '' : 'hidden'} md:block`}>
            <RichTextDisplay content={post.content} />
          </div>
          <div className="hidden h-[300px] w-full overflow-y-auto md:block">
            <CommentsList
              isPostsLoading={false}
              postId={post.id}
              preloadedComments={post.activities}
              onCommentLikePressed={onCommentLikePressed}
            />
          </div>

          <hr />

          <div className="flex w-full justify-between">
            <FooterActionButton
              text={`${isMobile ? `(${post.reactionsCount || 0})` : `${sodiumConfig.keywords.likes} (${post.reactionsCount || 0})`}`}
              icon={sodiumConfig.icons.likePressed}
              iconActive={sodiumConfig.icons.likeDefault}
              active={post.liked}
              onIconPressed={_onLikePressed}
              onTextPressed={_onLikesCountPressed}
            />
            <FooterActionButton
              text={`${isMobile ? `(${post.commentsCount || 0})` : `${t('comments', { ns: 'common' })}  (${post.commentsCount || 0})`}`}
              icon={<MessageSquareMore className="h-6 w-4 text-gray-500" />}
              iconActive={
                <MessageSquareMore className="h-6 w-6 text-gray-500" />
              }
              onIconPressed={_onCommentPressed}
              onTextPressed={_onCommentsCountPressed}
            />
            <FooterActionButton
              text={isMobile ? '' : t('text-share', { ns: 'common' })}
              icon={<Repeat2 className="h-6 w-6 text-gray-500" />}
              iconActive={<Repeat2 className="h-6 w-6 text-gray-500" />}
              onIconPressed={_onSharePressed}
              onTextPressed={_onSharePressed}
            />
          </div>

          <div className="flex h-auto w-full flex-col">
            <CommentInput postId={post.id} />
          </div>
        </div>
      </div>
    </motion.div>
  )
}
