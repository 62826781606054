import type { SearchableAccount } from '@sodium/backend'
import type { FC } from 'react'
import Text from '@components/ui/Text'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { sodiumConfig } from '@src/sodiumConfig'
import {
  BuildingIcon,
  ContactRoundIcon,
  MessageSquareTextIcon,
} from 'lucide-react'

interface AccountExperienceProps {
  account: SearchableAccount | undefined
}

export const AccountExperience: FC<AccountExperienceProps> = ({ account }) => {
  const { t } = useTypedTranslation('onboarding')

  return (
    <div className="flex-1 bg-slate-100">
      <div className="mb-2 mt-2 min-h-10 bg-white px-6 py-4">
        <div className="flex-row gap-3 text-center align-top">
          <div className="pt-2">
            <BuildingIcon
              className="h-8 w-8"
              color={sodiumConfig.colors.primary}
            />
          </div>

          <div>
            <Text className="pb-1 text-lg font-semibold">
              {t('current_company_name', { ns: 'onboarding' })}
            </Text>
            <Text>{account?.current_company_name}</Text>
          </div>
        </div>
      </div>

      <div className="mb-2 mt-2 min-h-10 bg-white px-6 py-4">
        <div className="flex-row gap-3 text-center align-top">
          <div className="pt-2">
            <ContactRoundIcon
              className="h-8 w-8"
              color={sodiumConfig.colors.primary}
            />
          </div>

          <div>
            <Text className="pb-1 text-lg font-semibold">
              {t('current_position_name', { ns: 'onboarding' })}
            </Text>
            <Text className="">{account?.current_position_name}</Text>
          </div>
        </div>
      </div>

      <div className="mb-12 mt-2 min-h-10 bg-white px-6 py-4">
        <div className="flex-row gap-3 text-center align-top">
          <div className="pt-2">
            <MessageSquareTextIcon
              className="h-8 w-8"
              color={sodiumConfig.colors.primary}
            />
          </div>

          <div className="-ml-1 pr-12">
            <Text className="pb-1 pt-2 text-lg font-semibold">
              {t('short-bio', { ns: 'onboarding' })}
            </Text>
          </div>
        </div>

        <div className="pt-3">
          <Text className="mb-10">{account?.quickBio}</Text>
        </div>
      </div>
    </div>
  )
}
