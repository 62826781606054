import type { FC } from 'react'
import { backendHook } from '@api/trpc'
import { Button } from '@goatlab/react-ui'
import { Clock, RotateCw } from 'lucide-react'

interface SocialConnectionButtonProps {
  accountId: string
  userId: string
}

export const SocialConnectionButton: FC<SocialConnectionButtonProps> = ({
  accountId,
  userId,
}) => {
  const utils = backendHook.useUtils()
  const getSocialConnection =
    backendHook.backend.account.getSocialConnectionByAccountId.useQuery({
      accountId,
    })
  const followAccountHook =
    backendHook.backend.friendship.requestFriendship.useMutation({
      onSettled: async () => {
        await getSocialConnection.refetch()
      },
    })
  const unFollowAccountHook =
    backendHook.backend.friendship.cancelFriendshipRequest.useMutation({
      onSettled: async () => {
        await Promise.all([
          getSocialConnection.refetch(),
          utils.backend.friendship.getFriendshipNotificationsCount.refetch(),
          utils.backend.friendship.getFriendsCount.refetch(),
          utils.backend.friendship.getFriends.refetch({
            accountId: userId,
            perPage: 10,
            q: '',
          }),
        ])
      },
    })

  const onFollowPressed = async () => {
    await followAccountHook.mutateAsync({
      friendId: String(accountId),
    })
  }

  const onUnFollowPressed = async () => {
    await unFollowAccountHook.mutateAsync({
      friendId: String(accountId),
    })
  }

  const socialConnection = getSocialConnection.data

  if (getSocialConnection.isPending) {
    return (
      <Button disabled size={'sm'} variant={'default'}>
        <RotateCw className="mr-2 h-4 w-4 animate-spin" />
      </Button>
    )
  }

  if (socialConnection?.friendshipStatus === true) {
    return (
      <Button
        onClick={onUnFollowPressed}
        disabled={unFollowAccountHook.isPending}
        loading={unFollowAccountHook.isPending}
        size={'sm'}
        variant={'default'}
      >
        Unfollow
      </Button>
    )
  }

  if (
    socialConnection?.friendshipStatus === false &&
    socialConnection.friendshipStatusSlug === 'pending'
  ) {
    return (
      <Button size={'sm'} disabled variant={'default'}>
        <Clock className="mr-2 h-4 w-4 animate-spin" />
        Awaiting response
      </Button>
    )
  }

  if (
    socialConnection?.friendshipStatus === false &&
    socialConnection.friendshipStatusSlug === 'not_friends'
  ) {
    return (
      <Button
        size={'sm'}
        loading={followAccountHook.isPending}
        onClick={onFollowPressed}
        variant={'default'}
      >
        Follow
      </Button>
    )
  }

  return <></>
}
