import type { Post } from '@components/account/home/HomePosts'
import React, { useCallback } from 'react'
import { api } from '@api/api'
import { VirtualFeed } from '@components/ui/VirtualFeed'
import { useActivitiesStore } from '@sodium/shared-frontend-schemas'
import { AccountPost } from './AccountPost'

export const AccountPosts = ({
  accountId,
  onPostPressed,
}: {
  accountId?: string
  onPostPressed?: (props: { index: number; item: Post }) => void
}) => {
  const { index } = useActivitiesStore()
  const getAccountFeedHook = api.posts.useAccountFeedInfiniteQuery({
    visibleAccountId: accountId,
  })

  const renderItem = useCallback(
    ({ item, virtualItem }: { item: Post; virtualItem: any }) => (
      <div
        key={virtualItem.key}
        className="h-full w-full cursor-pointer bg-blue-400"
      >
        <AccountPost
          accountId={accountId}
          index={virtualItem.index}
          post={item}
          onPostPressed={onPostPressed}
        />
      </div>
    ),
    [accountId],
  )

  return (
    <div>
      <VirtualFeed
        scrollToIndex={index}
        infiniteQuery={getAccountFeedHook}
        renderItem={renderItem}
      />
    </div>
  )
}

export default AccountPosts
