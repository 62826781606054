import type { Post } from '@components/account/home/HomePosts'
import type { SearchableAccount } from '@sodium/backend/src/services/typesense/collections/account.collection'
import type { FC } from 'react'
import React, { Suspense } from 'react'
import { PostGridSkeleton } from '@components/account/home/PostGridSkeleton'
import { AccountExperience } from '@components/activities/AccountExperience'
import AccountPosts from '@components/activities/AccountPosts'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@goatlab/react-ui'
import { sodiumConfig } from '@src/sodiumConfig'
import { Grid3X3Icon, NotebookTextIcon } from 'lucide-react'

interface TabContainerProps {
  accountId: string
  account: SearchableAccount | undefined
  handlePostClick: (props: { index: number; item: Post }) => void
}

export const TabContainer: FC<TabContainerProps> = ({
  accountId,
  account,
  handlePostClick,
}) => {
  return (
    <Tabs defaultValue="Posts" className="w-full">
      <TabsList className="mb-1 grid w-full grid-cols-2">
        <TabsTrigger className="gap-2" value="Posts">
          <Grid3X3Icon color={sodiumConfig.colors.iconColor} />
          {'Posts'}
        </TabsTrigger>
        <TabsTrigger value="Experience">
          <NotebookTextIcon color={sodiumConfig.colors.iconColor} />
          {'Experience'}
        </TabsTrigger>
      </TabsList>
      <TabsContent
        value="Posts"
        className="mt-0 h-full w-full columns-3 [column-gap:3px]"
      >
        <Suspense fallback={<PostGridSkeleton />}>
          <AccountPosts
            accountId={String(accountId)}
            onPostPressed={handlePostClick}
          />
        </Suspense>
      </TabsContent>
      <TabsContent value="Experience">
        <AccountExperience account={account} />
      </TabsContent>
    </Tabs>
  )
}
