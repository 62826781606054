import React from 'react'
import { useRouter } from 'next/router'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { Button } from '@goatlab/react-ui'
import { useAccountStore } from '@sodium/shared-frontend-schemas'
import { MessageCircleMore } from 'lucide-react'
import { SocialConnectionButton } from './SocialConnectionButton'

interface ProfileActionButtonsProps {
  isAccountOwner: boolean
  onSharedProfile: () => void
  onSendMessage: () => void
  accountId?: string
}

export const ProfileActionButtons = ({
  isAccountOwner,
  onSharedProfile,
  onSendMessage,
  accountId,
}: ProfileActionButtonsProps) => {
  const router = useRouter()
  const { t } = useTypedTranslation()
  const { selectedAccount } = useAccountStore()

  if (isAccountOwner) {
    return (
      <div className="flex w-full flex-row items-center gap-4">
        <Button
          size="sm"
          variant={'default'}
          onClick={async () =>
            await router.push(`/account/${accountId}/settings`)
          }
        >
          {t('text-profile-edit', { ns: 'common' })}
        </Button>
        <Button size="sm" variant={'default'} onClick={onSharedProfile}>
          {t('text-profile-share', { ns: 'common' })}
        </Button>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-row items-center gap-4">
      <SocialConnectionButton
        userId={selectedAccount?.id || ''}
        accountId={String(accountId)}
      />
      <Button
        size={'sm'}
        loading={!accountId}
        variant={'default'}
        onClick={onSendMessage}
      >
        <MessageCircleMore className="mr-2 h-4 w-4" />
        Message
      </Button>
    </div>
  )
}
