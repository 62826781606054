import type { SearchableAccount } from '@sodium/backend'
import React, { memo } from 'react'
import Link from 'next/link'
import { ProfileBackgroundImageUpload } from '@components/ui/forms/images/ProfileBackgroundImageUpload'
import { ProfilePictureUpload } from '@components/ui/forms/images/ProfilePictureUpload'
import {
  defaultBackgroundImg,
  defaultProfileImg,
} from '@components/ui/images/images'
import Text from '@components/ui/Text'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { Button } from '@goatlab/react-ui'
import { ProfileActionButtons } from './ProfileActionButtons'

export const ProfileSpaceHeader = memo(
  ({
    account,
    accountId,
    isAccountOwner,
    sendMessage,
    onSharedProfile,
    postsCount = 0,
    followersCount = 0,
    followingCount = 0,
  }: {
    account: SearchableAccount | undefined
    accountId: string
    isAccountOwner: boolean
    sendMessage: () => void
    onSharedProfile: () => void
    postsCount?: number
    followersCount?: number
    followingCount?: number
  }) => {
    const { t } = useTypedTranslation()
    return (
      <>
        <div>
          <ProfileBackgroundImageUpload
            backgroundImageUrl={
              account?.backgroundImage || defaultBackgroundImg
            }
            fallbackText={account?.displayName}
            isAccountOwner={isAccountOwner}
          />
          <div className="flex h-36 w-full justify-between">
            <ProfilePictureUpload
              isAccountOwner={isAccountOwner}
              imageUrl={account?.profilePicture || defaultProfileImg}
              fallbackText={account?.displayName}
              changeImageText={t('text-change', { ns: 'common' })}
              showUploadButton={false}
              className={`absolute z-20 -mt-44 ml-4 h-[144px] w-[144px] rounded-full border-2 border-gray-200 dark:border-[rgb(29,34,38)] max-[1060px]:-mt-32 max-[1060px]:h-24 max-[1060px]:w-24 ${isAccountOwner ? 'cursor-pointer' : ''}`}
            />
            <div className="flex w-full flex-row items-start justify-end lg:justify-center">
              <div className="items-center p-2 pl-4">
                <div>
                  <Text className="text-center text-xl">{postsCount}</Text>
                </div>
                <div>
                  <Text className="text-sm">posts</Text>
                </div>
              </div>
              <div className="items-center p-2 pl-4">
                <div>
                  <Text className="text-center text-xl">{followersCount}</Text>
                </div>
                <div>
                  <Text className="text-sm">followers</Text>
                </div>
              </div>
              <div className="items-center p-2 pl-4">
                <div>
                  <Text className="text-center text-xl">{followingCount}</Text>
                </div>
                <div>
                  <Text className="text-sm">following</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="-mt-24 mb-4 flex flex-col pl-4">
          <Text
            variant="heading"
            className="text-2xl font-bold dark:text-white max-[990px]:text-[18px]"
          >
            {account?.displayName}
          </Text>
          <Text className="pb-1 font-medium">{account?.title}</Text>

          {account?.website && (
            <div className="flex-row items-center">
              <Button>
                <Link href={'xd'}>{account.website}</Link>
              </Button>
            </div>
          )}
        </div>
        <div className="space-x-4 p-4">
          <ProfileActionButtons
            isAccountOwner={isAccountOwner}
            accountId={accountId}
            onSendMessage={sendMessage}
            onSharedProfile={onSharedProfile}
          />
        </div>
      </>
    )
  },
)
