import type { Post } from '@components/account/home/HomePosts'
import type { SearchableAccount } from '@sodium/backend/src/services/typesense/collections/account.collection'
import type { FC } from 'react'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@components/auth/store/auth.store'
import { useActivitiesStore } from '@sodium/shared-frontend-schemas'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { defaultAccountImage } from '@src/utils/constants'
import { useChatActions } from '../ui/chat/store/ChatStore'
import { ParallelPostViewer } from './ParallelPostViewer'
import { ProfileSpaceHeader } from './ProfileSpaceHeader'
import { TabContainer } from './TabContainer'

interface ProfileSpaceViewProps {
  accountId: string
  account: SearchableAccount | undefined
}

export const ProfileSpaceView: FC<ProfileSpaceViewProps> = memo(
  ({ accountId, account }) => {
    const router = useRouter()
    const routes = useStateAwareRoutes()
    const { setCurrentChatId, setCurrentChatRecipient } = useChatActions()
    const { user } = useAuth()
    const [isAccountOwner, setIsAccountOwner] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const { setIndex, reset, index } = useActivitiesStore()

    const handlePostClick = ({ index }: { index: number; item: Post }) => {
      setIndex(index)
      setIsDialogOpen(true)
    }

    const handleClose = () => {
      setIsDialogOpen(false)
    }

    const sendMessage = useCallback(() => {
      if (!account) {
        return
      }
      setCurrentChatId(account.id)
      setCurrentChatRecipient({
        _id: account.id,
        name: account.displayName,
        avatar: account.profilePicture || defaultAccountImage,
      })
      void router.push(
        routes.dynamic.ACCOUNT_INBOX({
          accountId: account.id,
        }),
      )
    }, [account])

    const onShareProfile = useCallback(() => {
      if (!account) {
        return
      }
      console.log('Sharing profile')
    }, [account])

    useEffect(() => {
      setIsAccountOwner(user?.account?.id === accountId)
      return () => reset()
    }, [accountId, user])

    return (
      <div className="col-[1_/_span_12] row-[1_/_span_2] rounded-md bg-white dark:bg-[rgb(29,34,38)] max-[768px]:col-span-12 max-[768px]:row-[1_/_span_2] md:col-[1_/_span_9] lg:col-[2_/_span_7]">
        <ProfileSpaceHeader
          account={account}
          accountId={String(accountId)}
          isAccountOwner={isAccountOwner}
          sendMessage={sendMessage}
          onSharedProfile={onShareProfile}
          postsCount={0}
          followersCount={0}
          followingCount={0}
        />
        <TabContainer
          account={account}
          accountId={String(accountId)}
          handlePostClick={handlePostClick}
        />
        <ParallelPostViewer
          accountId={String(accountId)}
          currentIndex={index}
          isDialogOpen={isDialogOpen}
          onClose={handleClose}
        />
      </div>
    )
  },
)
