import type { FC } from 'react'
import React from 'react'
import { Layout } from '@components/layout/layout'
import { useSite } from '@contexts/site/site.context'
import { useScrollHeight } from '@goatlab/react-ui'
import clsx from 'clsx'

interface IProps {
  children: React.ReactNode
}

export const ProfileSpaceLayout: FC<IProps> = ({ children }) => {
  const { navbarOffset } = useScrollHeight()
  const { menuItems, isSpace } = useSite()

  // If there is menu, we display the page a bit lower
  const offset = isSpace && menuItems.length > 1 ? 30 : 0

  return (
    <Layout>
      <div
        style={{
          top: `${navbarOffset + offset}px`,
        }}
        className={clsx('relative h-full', {})}
      >
        {children}
      </div>
    </Layout>
  )
}
