import type { CarouselApi } from '@goatlab/react-ui'
import type { FC } from 'react'
import React from 'react'
import {
  Card,
  CardContent,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Dialog,
  DialogContent,
} from '@goatlab/react-ui'
import '@goatlab/react-ui'
import { api } from '@api/api'
import { AccountsLikedPostsDialog } from '@components/account/home/AccountsLikedPostsDialog'
import { CreatePostDialog } from '@components/account/home/CreatePostDialog'
import { AccountPostCarousel } from '@components/activities/AccountPostCarousel'

interface ParallelPostViewerProps {
  accountId: string
  currentIndex?: number
  isDialogOpen: boolean
  onClose: () => void
}

export const ParallelPostViewer: FC<ParallelPostViewerProps> = ({
  accountId,
  currentIndex = 0, // Valor por defecto para evitar undefined
  isDialogOpen,
  onClose,
}) => {
  const [carouselApi, setApi] = React.useState<CarouselApi>()
  const [, setCurrent] = React.useState(currentIndex)
  const [isShowLikedModal, setIsShowLikedModal] = React.useState(false)
  const [isShowPostModal, setIsShowPostModal] = React.useState(false)

  const showPostModal = async () => setIsShowPostModal(true)
  const showLikedModal = async () => setIsShowLikedModal(true)

  const { getHomeFeedHook } = api.hooks.useHomePostFeed({ accountId })

  const posts = getHomeFeedHook.data?.pages.flatMap((page) => page.data) ?? []

  React.useEffect(() => {
    if (!carouselApi) return

    const handleSelect = () => {
      setCurrent(carouselApi.selectedScrollSnap())
    }

    carouselApi.on('select', handleSelect)

    return () => {
      carouselApi.off('select', handleSelect)
    }
  }, [carouselApi])

  // Establecer el índice inicial cuando se abre el carrusel
  React.useEffect(() => {
    if (carouselApi && isDialogOpen) {
      carouselApi.scrollTo(currentIndex, true)
    }
  }, [carouselApi, currentIndex, isDialogOpen])

  return (
    <Dialog open={isDialogOpen} onOpenChange={onClose}>
      <DialogContent className="mx-auto flex h-full w-full max-w-full items-center justify-center border-none bg-transparent shadow-none">
        <Carousel
          setApi={setApi}
          className="relative h-full w-full items-center justify-center"
          opts={{
            loop: false,
            startIndex: currentIndex,
          }}
        >
          <CarouselContent className="max-h-full max-w-[330px] md:max-h-dvh md:max-w-5xl">
            {posts?.map((post, index) => (
              <CarouselItem key={index}>
                <Card>
                  <CardContent className="flex h-full w-full items-center justify-center">
                    <AccountPostCarousel
                      isPreview={false}
                      isViewable={true}
                      post={post}
                      onLikesCountPressed={showLikedModal}
                      onOptionsPressed={() => {}}
                      showPostModal={showPostModal}
                    />
                    <CreatePostDialog
                      isDialogOpen={isShowPostModal}
                      setIsDialogOpen={setIsShowPostModal}
                    />
                    <AccountsLikedPostsDialog
                      isDialogOpen={isShowLikedModal}
                      setIsDialogOpen={setIsShowLikedModal}
                    />
                  </CardContent>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          {/* Botones de navegación */}
          <CarouselPrevious className="absolute left-1 top-1/2 z-10 -translate-y-1/2 transform rounded-full bg-gray-200 p-2 shadow-md" />
          <CarouselNext className="absolute right-1 top-1/2 z-10 -translate-y-1/2 transform rounded-full bg-gray-200 p-2 shadow-md" />
        </Carousel>
      </DialogContent>
    </Dialog>
  )
}
